import React from 'react'
import './App.css';
import Logo from './common/Logo';
import Home from './pages/Home';


function App() {
    return (
        <div className="App container">
            <Logo intro/>
            <Home />
        </div>
    );
}

export default App;
