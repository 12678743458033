import React from 'react'

import './style.css'

const Home = () => {
    return (
        <div id="home">
    
        </div>
    )
}

export default Home
