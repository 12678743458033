import React from 'react'

import { ReactComponent as Intro } from '../../assets/logo-intro.svg'
import { ReactComponent as LogoImg } from '../../assets/logo.svg'

import './intro.css'
import './logo.css'

const Logo = ({intro}) => { 
    
    if (intro) {
        return (
            <div className="intro-bg">
                <Intro/>
            </div>
        )
    } else {
        return <LogoImg/>
    }
}

export default Logo
